export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Wizard',
    route: 'wizard',
    icon: 'FileIcon',
  },
]
